import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/home/">Strona główna</a>
                </li>
                <li>
                  <a href="/produkty/">Produkty</a>
                </li>
                <li>
                  <a href="/poradnik/">Poradnik</a>
                </li>
                <li>
                  <a href="/dobierz-lek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/pytania-i-odpowiedzi/">Pytania i odpowiedzi</a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Strona główna</a>
            <a href="/katar-i-bol-glowy/" className="sel">
              {" "}
              &gt; Katar i uczucie ucisku w głowie
            </a>
          </div>
          <div id="page_content">
            <div className="col1">
              <div className="related">
                <p className="name">Dowiedz się więcej</p>
                <div className="line">
                  <a href="/poradnik/przeziebienie/domowe_sposoby_na_walke_z_przeziebieniem/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage:'url("/web/kcfinder/uploads/files/artykuly/GettyImages-1030072086.jpg")',}} />
                    </div>
                    <div className="c2">
                      Domowe sposoby na walkę z przeziębieniem{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/poradnik/przeziebienie/cztery_fazy_rozwoju_przeziebienia/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage:'url("/web/kcfinder/uploads/files/shutterstock_87834502.jpg")',}} />
                    </div>
                    <div className="c2">Cztery fazy rozwoju przeziębienia </div>
                  </a>
                </div>
              </div>
              <a href="/dobierz-lek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/zrobtest.png" />
              </a>
              <a href="/reklama-telewizyjna/" className="link_box video">
                <img src="/web/kcfinder/uploads/files/box/SudafedHAthumbnail.jpg" />
              </a>
              <a href="/katar-i-bol-glowy/" className="quiz_btn" />
            </div>
            <div className="col2">
              <div className="wysiwyg">
                <div>
                  <img
                    alt
                    src="/kcfinder/uploads/files/box/kopiaheading.png"
                    style={{
                      width: "299px",
                      height: "auto"
                    }}
                  />
                </div>
                <div>&nbsp;</div>
                <div
                  style={{
                    "-webkit-text-align": "justify",
                    "text-align": "justify"
                  }}
                >
                  <div>
                    Katar, uczucie ucisku w głowie i zatokach to częste oznaki
                    zbliżającego się{" "}
                    <a href="/slowniczek/#przeziebienie" target="_blank">
                      przeziębienia
                    </a>{" "}
                    spowodowanego zakażeniem wirusowym<sup>2</sup>. Podczas
                    rozpoczynającej się infekcji na takie objawy skarży się
                    prawie połowa pacjentów<sup>3</sup>. Symptomy te znacznie
                    utrudniają normalne funkcjonowanie. Podjęcie szybkiego
                    działania w początkowej fazie choroby pomaga uniknąć jej
                    dalszego rozwoju czy pojawienia się powikłań.&nbsp;
                  </div>
                  <div>&nbsp;</div>
                  <div>
                    Jeszcze na początkowym etapie objawy infekcji można leczyć
                    lekami dostępnymi bez recepty. Najbardziej wskazany jest
                    wtedy lek, który jednocześnie zwalcza: katar oraz uczucie
                    ucisku w głowie i zatokach.&nbsp;
                  </div>
                </div>
                <div>&nbsp;</div>
                <div>
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    1&nbsp;Dotyczy uczucia ucisku w zatokach spowodowanego
                    nagromadzoną wydzieliną i obrzękiem błony śluzowej nosa
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    2 Grypa i przeziębienie. Porady lekarza rodzinnego,
                    opracowanie zbiorowe, Literat, 2012 s.3
                  </span>
                </div>
                <div>
                  <span
                    style={{
                      "font-size": "11px"
                    }}
                  >
                    3 R. Eccles, M. Jawad, S. Jawad, D. Ridge, M. North, E.
                    Jones &amp; I. Burnett (2006) Efficacy of a
                    paracetamol–pseudoephedrine combination for treatment of
                    nasal congestion and pain-related symptoms in upper
                    respiratory tract infection, Current Medical Research and
                    Opinion, 22:12, 2411-2418, DOI: 10.1185/030079906X154105
                  </span>
                </div>
                <div>&nbsp;</div>
              </div>
              <div className="drugs">
                <div className="box">
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/tabletki_02.png" />
                  </div>
                  <div className="info">
                    <p className="name">
                      SUDAFED<sup>®</sup> <br />
                      TABLETKI
                    </p>
                    <div className="desc">
                      Odblokowuje nos oraz eliminuje uczucie ucisku w głowie i
                      zatokach
                      <span className="note_nr" />
                    </div>
                    <a href="/produkty/sudafed_tabletki/" className="more">
                      WIĘCEJ
                    </a>
                  </div>
                </div>
              </div>
              <div className="clear note"></div>
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <footer>
          <div className="page_width">
            <div className="drug_alert"></div>
            <p
              style={{
                "font-size": "10px",
                "-webkit-text-align": "right",
                "text-align": "right",
                padding: "0 0 10px"
              }}
            >
              MAT/6769/03/2019
            </p>
          </div>
          <div className="footer_content">
            <div className="page_width">
              <ul id="footer_menu">
                <li>
                  <a href="/polityka-prywatnosci/">Polityka prywatności</a>
                </li>
                <li>
                  <a href="/polityka-cookies/">Polityka cookies</a>
                </li>
                <li>
                  <a href="/nota-prawna/">Nota prawna</a>
                </li>
                <li>
                  <a href="/mapa-strony/">Mapa strony</a>
                </li>
                <li>
                  <a href="/slowniczek/">Słowniczek</a>
                </li>
                <li>
                  <a id="ot-sdk-btn" className="ot-sdk-show-settings" rel="nofollow"> Ustawienia plików Cookie </a>
                </li>
              </ul>
              <div className="rel footer_info">
                <br />
                Podmiot odpowiedzialny: McNeil Products Limited <br />© JNTL Consumer Health (Poland) sp. z o.o. 2023<br />
                JNTL Consumer Health (Poland) sp. z o.o. w ramach swojej działalności gospodarczej prowadzi hurtowy obrót produktami leczniczymi. Jeżeli jesteś przedsiębiorcą prowadzącym aptekę ogólnodostępną i jesteś zainteresowany nabyciem produktów leczniczych oferowanych przez nas, uprzejma prośba o kontakt z działem Customer Service email: <a href="mailto:apteki@kenvue.com">apteki@kenvue.com</a>. Minimalna wartość zamówienia 500 PLN brutto. Zamówienia realizowane będą wyłącznie po otrzymaniu pełnej przedpłaty.
              </div>
            </div>
          </div>
        </footer>{" "}
      </div>
    );
  }
}

export default Page;
